;var dropdown = (function($) {

	$(function () {

		$("#sort").change(function() {
			var option = $("#sort").val();
			
			if(option == "date" || option == "city") {
				$("#openHouses").load("/umbraco/surface/openhouse/OpenHouses?sort=" + option);
			}
		});

	});

})(jQuery);