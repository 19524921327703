/**
 * InViewPort module
 * - Returns whether an element is in viewport
 * PAram middle return when object middle of viewport
 */
;var inviewport = (function($, window) { // eslint-disable-line no-extra-semi
    /**
     * Return whether an element is in viewport
     * @param element
     * @returns {string|*}
     */
    return function (element, middle) {
        /**
         * If client doesn't support `getBoundingClientRect` always return true
         */
        if (element === undefined || element.getBoundingClientRect === undefined) {
            return true;
        }

        /**
         * Get element and window bounds
         * @type {ClientRect}
         */
        var bounds = element.getBoundingClientRect(),
            vpWidth = $(window).width(),
            vpHeight = $(window).height(); // load exact from middle.

            vpHeight = vpHeight * 2; //Fix to load more images than images in viewport to prevent loading while scrolling through images

            if (middle) {
              vpHeight = vpHeight/1.8;
            }
        /**
         * Return whether the bounds are within the viewport
         */
        return (
            (bounds.top > 0 || bounds.bottom > 0) && (bounds.left > 0 || bounds.right > 0) &&
            (bounds.top < vpHeight || bounds.bottom < vpHeight) && (bounds.left < vpWidth || bounds.right < vpWidth)
        );
    };
})(jQuery, window);

/**
 * Export for unit tests
 */
if ("object" == typeof module && "object" == typeof module.exports) {
    module.exports = inviewport;
}
